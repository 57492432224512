<template>
  <v-container fluid>
    <v-card elevation="4" class="mt-0">
          <products-tab></products-tab>
    </v-card>
  </v-container>
</template>

<script>
export default {
  components: {
    ProductsTab: () => import("./components/tabs/ProductsTab.vue"),
  },

  async mounted() {
    try {
      await this.$store.dispatch("brands/list");
      await this.$store.dispatch("hubs/list");
      await this.$store.dispatch("categories/list");
      await this.$store.dispatch("stockManagements/list", { itemsPerPage: 30 });
      await this.$store.dispatch("products/listSelectedHubIds");
      await this.$store.dispatch("statuses/list");
    } catch (error) {
      if (error.response?.status == "401") {
        this.$store.dispatch("logout");
      }
      this.$store.dispatch("alerts/error", error.response?.data?.message);
    }
  },

  data() {
    return {
      tab: null,
      items: [
        {
          tab: this.$t("products.products"),
          content: "products",
        },
      ],
    };
  },
};
</script>
